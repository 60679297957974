import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './i18n/en.json';
import zh from './i18n/zh.json';
import zhTW from './i18n/zh-TW.json';

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: en
    },
    zh: {
      translation: zh
    },
    'zh-TW': {
      translation: zhTW
    }
  },
  lng: 'zh',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false
  }
});

export default i18n;
