import React, { useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import '../../style/ContactUs.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons'; // Import spinner icon

const ContactUs = () => {
  const { t } = useTranslation();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false); // Loading state

  const handleSubmit = async (e) => {
    e.preventDefault();

    const name = e.target.name.value;
    const email = e.target.email.value;
    const message = e.target.message.value;

    const emailData = {
      to: "jenson@hzxizigroup.com",
      subject: `优发咨询 Message from ${name}`,
      text: message,
      replyTo: email
    };

    try {
      setLoading(true); // Start loading
      await axios.post(process.env.NODE_ENV === 'production' ? 'https://backend.yosend.me/send-email' : 'http://localhost:3001/send-email', emailData);
      setFormSubmitted(true);
      setLoading(false); // Stop loading when done
    } catch (err) {
      console.error('Error sending email:', err);
      setError('Please try again later. 请稍后再试。請稍後再試。');
      setLoading(false); // Stop loading on error
    }
  };

  return (
    <section id="contact-us" className="contact-us-section">
      <h2>{t('contactus.contact_us')}</h2>
      <p>{t('contactus.contact_us_description')}</p>

      {!formSubmitted && !error && (
        <form className="contact-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <input
              type="text"
              id="name"
              name="name"
              placeholder={t('contactus.name_placeholder')}
              required
            />
          </div>
          <div className="form-group">
            <input
              type="email"
              id="email"
              name="email"
              placeholder={t('contactus.email_placeholder')}
              required
            />
          </div>
          <div className="form-group">
            <textarea
              id="message"
              name="message"
              placeholder={t('contactus.message_placeholder')}
              required
            ></textarea>
          </div>
          {!loading ? (
            <button type="submit" className="btn-primary">
              {t('contactus.submit')}
            </button>
          ) : (
            <div className="spinner-container">
              <FontAwesomeIcon icon={faSpinner} spin className="spinner" />
            </div>
          )}
        </form>
      )}

      {formSubmitted && (
        <p className="thank-you-message">
          {t('contactus.thank_you')}
        </p>
      )}

      {error && (
        <p className="error-message">
          {error}
        </p>
      )}
    </section>
  );
};

export default ContactUs;
