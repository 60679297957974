import React, { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Card, Button, Col, Row, Modal } from 'react-bootstrap';
import { Link } from 'react-scroll';
import '../../style/MembershipPlans.scss';

// Example image to display in the modal
import salesQR from '../../sales_qr_code.jpeg';

const pricingPlans = [
  {
    nameKey: "membership.plans.starter.name",
    sloganKey: "membership.plans.starter.slogan",
    priceKey: "membership.plans.starter.price",
    includedKey: "membership.plans.starter.included"
  },
  {
    nameKey: "membership.plans.pro.name",
    sloganKey: "membership.plans.pro.slogan",
    priceKey: "membership.plans.pro.price",
    includedKey: "membership.plans.pro.included"
  },
  {
    nameKey: "membership.plans.enterprise.name",
    sloganKey: "membership.plans.enterprise.slogan",
    priceKey: "membership.plans.enterprise.price",
    includedKey: "membership.plans.enterprise.included"
  }
];

const MembershipPlans = () => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  return (
    <>
      <section className="membership-plans">
        <h2>{t('membership.membership_plans')}</h2>
        <p className="description">{t('membership.description')}</p>
        <Row className="justify-content-center">
          {pricingPlans.map((plan, index) => (
            <Col key={index} md={4} className="d-flex pricing-col">
              <Card className="pricing-card">
                <Card.Body className="d-flex flex-column">
                  <Card.Title>{t(plan.nameKey)}</Card.Title>
                  <Card.Subtitle className="mb-2 text-muted slogan">{t(plan.sloganKey)}</Card.Subtitle>
                  <Card.Text className="price">
                    {t(plan.priceKey)}<small> /{t('membership.year')}</small>
                  </Card.Text>
                  <Card.Text>
                    <strong>{t('membership.included')}</strong>
                    <ul>
                      {t(plan.includedKey, { returnObjects: true }).map((item, idx) => (
                        <li key={idx}><Trans>{item}</Trans></li>
                      ))}
                    </ul>
                  </Card.Text>
                  <div className="btn-container mt-auto">
                    <Link
                      to="contact-us"
                      smooth={true}
                      duration={500}
                      className="btn btn-primary mb-2"
                    >
                      {t('membership.getStarted')}
                    </Link>
                    <Button variant="primary" onClick={handleShowModal}>
                      {t('membership.readyToBuy')}
                    </Button>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </section>

      {/* Modal for displaying the image */}
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        centered
        dialogClassName="qr-modal" // Use the new custom class "qr-modal"
        backdrop="static" // Prevent closing on backdrop click
        keyboard={false} // Prevent closing with the ESC key
      >
        <Modal.Header closeButton>
          <Modal.Title>{t('membership.readyToBuy')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p>{t('membership.scanQrInstructions')}</p>
            <img src={salesQR} alt="Ready to Buy" style={{ width: '100%', height: 'auto' }} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            {t('close')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default MembershipPlans;
