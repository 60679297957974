import React, { useState } from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import { Carousel } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import '../../style/SubSection.scss'; // Import the SubSection SCSS file

const SubSection = ({ description, images, left_or_right }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const onChange = (visible) => {
    if (visible) {
      setIsVisible(true);
    }
  };

  const handleSelect = (selectedIndex) => {
    setActiveIndex(selectedIndex);
  };

  return (
    <VisibilitySensor onChange={onChange} partialVisibility offset={{ bottom: -200 }} delayedCall>
      <div className={`sub-section ${left_or_right} ${isVisible ? 'in-view' : ''}`}>
        <div className="description">
          <p>{description}</p>
        </div>
        <div className="carousel-container">
          <Carousel
            activeIndex={activeIndex}
            onSelect={handleSelect}
            prevIcon={activeIndex !== 0 ? <FontAwesomeIcon icon={faAngleLeft} /> : null}
            nextIcon={activeIndex !== images.length - 1 ? <FontAwesomeIcon icon={faAngleRight} /> : null}
          >
            {images.map((image, index) => (
              <Carousel.Item key={index}>
                <img
                  className="d-block w-100"
                  src={image}
                  alt={`Slide ${index}`}
                />
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
      </div>
    </VisibilitySensor>
  );
};

export default SubSection;
