import React from 'react';
import { Link } from 'react-scroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { useTranslation, Trans } from 'react-i18next';
import '../style/Intro.scss'; // Import the Intro SCSS file

const Intro = () => {
  const { t } = useTranslation();

  return (
    <section className="App-intro">
      <div className="intro-text">
        <h1>{t('intro.title')}</h1>
        <h3>
          <Trans i18nKey="intro.subtitle"></Trans>
        </h3>
      </div>
      <Link to="solutions-services" smooth={true} duration={500}>
        <button className="intro-button">
          {t('intro.learnMore')}
        </button>
      </Link>
      <div className="arrow-container">
        <FontAwesomeIcon icon={faAngleDown} className="arrow-icon" />
      </div>
    </section>
  );
};

export default Intro;
