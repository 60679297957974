import React, { useState, useEffect } from 'react';
import './style/App.scss';
import './style/fonts.scss';
import logo from './logo.png';
import chineseLogo from './logo_chinese.png';
import { useTranslation } from 'react-i18next';
import Nav from './components/Nav';
import Intro from './components/Intro';
import SolutionsServices from './components/Sections/SolutionsServices';
import MembershipPlans from './components/Sections/MembershipPlans';
import ContactUs from './components/Sections/ContactUs';
import Footer from './components/Footer';

const App = () => {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState('zh');
  const [isSticky, setIsSticky] = useState(false);

  const toggleLanguage = () => {
    const newLanguage = language === 'en' ? 'zh' : (language === 'zh' ? 'zh-TW' : 'en');
    setLanguage(newLanguage);
    i18n.changeLanguage(newLanguage);
  };

  const handleLogoClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // This makes the scroll smooth
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.scrollY > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Determine which logo to display based on the current language
  const currentLogo = language === 'en' ? logo : chineseLogo;

  return (
    <div className="App">
      <header className={`App-header ${isSticky ? 'sticky' : ''}`}>
        <img src={currentLogo} className="App-logo" alt="logo" onClick={handleLogoClick} style={{ cursor: 'pointer' }} />
        <Nav toggleLanguage={toggleLanguage} />
      </header>
      <Intro />
      <main>
        <SolutionsServices />
        <MembershipPlans />
        <ContactUs />
      </main>
      <Footer />
    </div>
  );
};

export default App;
