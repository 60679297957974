import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-scroll';

const Nav = ({ toggleLanguage }) => {
  const { t } = useTranslation();

  return (
    <nav className="App-nav">
      <Link to="solutions-services" smooth={true} duration={500}>{t('solutions_services')}</Link>
      <Link to="membership-plans" smooth={true} duration={500}>{t('membership_plans')}</Link>
      <Link to="contact-us" smooth={true} duration={500}>{t('contact_us')}</Link>
      <button onClick={toggleLanguage} className="translate-button">
        {t('translate')}
      </button>
    </nav>
  );
};

export default Nav;
