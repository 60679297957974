import React from 'react';
import { useTranslation } from 'react-i18next';
import SubSection from './SubSection';
import '../../style/SolutionsServices.scss'; // Import the SolutionsServices SCSS file

// Import the images directly
import customerManagementImage from '../../solutions_services_images/customer_ management_1.png';
import customerGroupManagement from '../../solutions_services_images/group_management_1.png';
import campaignManagementImage1 from '../../solutions_services_images/campaign_management_1.png';
import campaignManagementImage2 from '../../solutions_services_images/campaign_management_2.png';
import campaignManagementImage3 from '../../solutions_services_images/campaign_management_3.png';
import campaignManagementImage4 from '../../solutions_services_images/campaign_management_4.png';
import trackingStatsImage1 from '../../solutions_services_images/tracking_stats_1.png';
import trackingStatsImage2 from '../../solutions_services_images/tracking_stats_2.png';
import trackingStatsImage3 from '../../solutions_services_images/tracking_stats_3.png';
import otherFeatures1 from '../../solutions_services_images/delivery_server.png';
import otherFeatures2 from '../../solutions_services_images/email_templates.png';
import otherFeature3 from '../../solutions_services_images/blacklisted_email.png';
import otherFeature4 from '../../solutions_services_images/daily_activity.png';

const SolutionsServices = () => {
  const { t } = useTranslation();

  const subSections = [
    {
      description: t('solutions.description1'),
      images: [customerManagementImage, customerGroupManagement],
    },
    {
      description: t('solutions.description2'),
      images: [campaignManagementImage1, campaignManagementImage2, campaignManagementImage3, campaignManagementImage4],
    },
    {
      description: t('solutions.description3'),
      images: [trackingStatsImage1, trackingStatsImage2, trackingStatsImage3],
    },
    {
      description: t('solutions.description4'),
      images: [otherFeatures1, otherFeatures2, otherFeature3, otherFeature4],
    },
  ];

  return (
    <section className="solutions-services">
      <h2>{t('solutions_services')}</h2>
      {subSections.map((subSection, index) => {
        const left_or_right = index === 0 || index % 2 === 0 ? 'left' : 'right';
        return (
          <SubSection
            key={index}
            description={subSection.description}
            images={subSection.images}
            left_or_right={left_or_right}
          />
        );
      })}
    </section>
  );
};

export default SolutionsServices;
